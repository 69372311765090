<template>
  <div>
    <breadcrumb name="User" title="Edit User" subtitle="Add" @onTitleClicked="backToList"/>
    <div class="body">
      <h6 class="mandatory">Fields marked with (*) are mandatory</h6>
      <div>
        <div class="english">
          <vs-input
            label="Name *"
            v-model="name"
            size="medium"
            class="input"
            :danger="flags.name"
            danger-text="This field is mandatory"
            disabled
          />
        </div>
        <div class="arabic">
          <vs-input
            label="Mobile *"
            v-model="mobile"
            size="medium"
            class="input"
            type="number"
            :danger="flags.mobile"
            danger-text="Invalid Mobile Number"
          />
        </div>
      </div>
      <div class="optionBtns">
        <vs-button
          :disabled="invalidForm"
          class="mr-5 save"
          icon-pack="feather"
          icon="icon-save"
          @click="confirmSaveData"
        >Save
        </vs-button>
        <vs-button
          class="ml-5 cancel"
          type="border"
          color="primary"
          @click="resetData"
        >Cancel
        </vs-button>
      </div>
    </div>
  </div>
</template>

<script>
  import {ChevronsRightIcon, HomeIcon} from "vue-feather-icons";
  import Breadcrumb from "../../../components/general/breadcrumb";

  export default {
    name: "edit-user",
    components: {Breadcrumb, HomeIcon, ChevronsRightIcon},
    props: ["item"],
    data() {
      return {
        name: this.item.name,
        mobile: this.item.mobile,
        flags: {
          name: false,
          mobile: false,
        }
      };
    },
    methods: {
      backToList() {
        this.$emit("cancel");
      },
      confirmSaveData() {
        this.$vs.dialog({
          title: "Confirm",
          text: "You are going to save these data!",
          color: "primary",
          accept: () => this.saveData(),
          type: "confirm"
        });
      },
      resetData() {
        this.backToList();
      },
      saveData() {
        this.$vs.loading();
        let formData = new FormData();

        formData.append("mobile", this.mobile);
        formData.append("name", this.name);
        this.$http
          .post(`/user/${this.item.id}`, formData)
          .then(() => {
            this.$emit("saved");
            this.$emit("cancel");
            this.$vs.loading.close();
            this.$vs.notify({
              title: "Saved",
              text: "Data is Saved Successfully",
              color: "primary"
            });
          })
          .catch(() => {
            this.$vs.loading.close();
            //console.log(e);
            this.$vs.notify({
              title: "Error",
              text: "Error is occurred!\nCouldn't Save Data!",
              color: "danger"
            });
          });
      }
    },
    computed: {
      invalidForm() {
        if (
          this.flags.name ||
          this.flags.mobile
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    watch: {
      name(v) {
        v === ""
          ? (this.flags.name = true)
          : (this.flags.name = false);
      },
      mobile(v) {
        const regExp = /^(002|\\+2)?(01)(0|1|2|5).{8}$/;
        !regExp.test(v) || v === "" ? (this.flags.mobile = true) : (this.flags.mobile = false);
      },
    }
  };
</script>

<style scoped lang="scss">
  @import "../../basic-styles/mixins";

  %flex {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  .img-fluid {
    border: 1px solid #00000030;
    height: auto;
    width: 200px;
    max-width: 100%;
    border-radius: 20px;
    @include margin-x(20px);
  }

  .images {
    @include margin-y(30px);
    text-align: center;
  }

  .optionBtns {
    @include margin-x(20px);
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .save {
      position: relative;
      left: 25px;
    }
  }

  .input {
    width: 100%;
    @include margin-y(30px);
  }

  .arabic,
  .english {
    @extend %flex;
    flex-flow: column;
    width: 100%;
    padding: 20px;
  }

  h1 {
    margin-bottom: 25px;
  }

  .inputs {
    @extend %flex;
  }

  ul {
    li {
      display: inline;
    }
  }

  .clr {
    clear: both;
  }

  $primaryColor: #054f7c;

  .breadcrumb {
    margin: 20px;
  }

  .back-to {
    cursor: pointer;
    color: $primaryColor;
    font-weight: bold;
  }

  .main-component {
    color: $primaryColor;
  }

  .required {
    color: red;
    font-size: 1.5rem;
  }

  .mandatory {
    color: red;
  }

  .arabic,
  .english {
    width: 50%;
    display: inline-block;
    vertical-align: top;
  }

  .error {
    color: red;
    text-align: center;
    margin-bottom: 20px;
  }

  .body {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
  }

  .img-uploader {
    height: 200px;
    max-width: 100%;
    width: auto;
    border-radius: 20px;
    padding: 10px;
  }

  .custom-class {
    color: $primaryColor;
  }

  .title {
    font-size: 2rem;
    font-weight: 400;
  }

  .separator {
    font-size: 2rem;
    font-weight: 100;
    color: #ccc;
  }

  .label {
    color: #00000099;
    padding-left: 5px;
  }

  .settings {
    margin: 30px 0 30px 30px;

    .header {
      margin-bottom: 10px;
    }
  }

  label {
    display: block;
    color: rgba(0, 0, 0, 0.6);
    font-size: 12px;
    margin-top: 20px;
  }

  select {
    width: 100%;
    padding: 8px;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: rgba(0, 0, 0, 0.8);
  }
</style>
