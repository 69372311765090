<template>
    <div>
        <edit-data
            :item="item"
            v-if="edit"
            @cancel="edit = false"
            @saved="getData"
        />
        <show-data
            :item="item"
            v-if="show"
            @cancel="show = false"
            @saved="getData"
        />
        <div v-if="!edit && !show">
            <breadcrumb name="User" title="All Users" subtitle="List" />

            <div id="data-list-thumb-view" class="data-list-container">
                <vs-row style="width: 55%; margin-left: 1%">
                    <vs-col
                        vs-sm="4"
                        vs-lg="4"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                    >
                        <vs-select
                            id="brands"
                            placeholder="Brands"
                            style="width: 100%; margin-top: 15px"
                            class="selectExample"
                            label="Brands"
                            v-model="brand_id"
                        >
                            <vs-select-item
                                :key="index"
                                :value="brand.id"
                                :text="brand.name_en"
                                v-for="(brand, index) in brands"
                            />
                        </vs-select>
                    </vs-col>
                    <vs-col
                        v-if="brand_id"
                        vs-sm="4"
                        vs-lg="4"
                        vs-type="flex"
                        vs-justify="center"
                        vs-align="center"
                    >
                        <vs-select
                            v-if="models.length > 0"
                            id="models"
                            placeholder="models"
                            style="width: 100%; margin-top: 15px"
                            class="ml-1"
                            label="Models"
                            v-model="model_id"
                        >
                            <vs-select-item
                                :key="index"
                                :value="model.id"
                                :text="model.name_en"
                                v-if="model.brand.id === brand_id"
                                v-for="(model, index) in models"
                            />
                        </vs-select>
                        <div
                            v-else
                            style="
                                width: 100%;
                                margin-top: 35px;
                                margin-left: 50px;
                            "
                        >
                            Loading...
                        </div>
                    </vs-col>
                    <vs-col
                        vs-sm="4"
                        vs-lg="4"
                        vs-type="flex"
                        vs-justify="start"
                        vs-align="center"
                    >
                        <vs-button
                            style="margin-top: 35px"
                            class="ml-1"
                            color="success"
                            type="filled"
                            @click="getData"
                        >
                            Filter
                        </vs-button>
                    </vs-col>
                </vs-row>
                <vs-table
                    ref="table"
                    :sst="true"
                    @search="handleSearch"
                    @sort="handleSort"
                    search
                    :data="items"
                >
                    <div
                        slot="header"
                        class="flex flex-wrap-reverse items-center flex-grow justify-between"
                    >
                        <div class="flex flex-wrap-reverse items-center">
                            <vs-button
                                color="primary"
                                type="filled"
                                @click="exportData"
                                >Export Requests</vs-button
                            >
                        </div>

                        <!-- ITEMS PER PAGE -->
                        <table-header
                            :current-page="currentPage"
                            :items-per-page="itemsPerPage"
                            :length="items.length"
                            :queried-items="queriedItems"
                            @onChangeItemsPerPage="itemsPerPage = $event"
                        />
                    </div>

                    <template slot="thead">
                        <vs-th sort-key="name_en">Avatar</vs-th>
                        <vs-th sort-key="name_en">Name</vs-th>
                        <vs-th sort-key="name_ar">Mobile</vs-th>
                        <vs-th sort-key="name_ar">Email</vs-th>
                        <vs-th sort-key="name_ar">National ID</vs-th>
                        <vs-th>Action</vs-th>
                    </template>

                    <template slot-scope="{ data }">
                        <tbody>
                            <vs-tr
                                :data="tr"
                                :key="indextr"
                                v-for="(tr, indextr) in data"
                            >
                                <image-row :data="tr.photo" />
                                <string-row :data="tr.name" />
                                <string-row :data="tr.mobile" />
                                <string-row :data="tr.email" />
                                <string-row :data="tr.national_id" />
                                <actions-row
                                    @onEditClicked="editData(tr)"
                                    @onShowClicked="showData(tr)"
                                    :deletable="false"
                                    show
                                />
                            </vs-tr>
                        </tbody>
                    </template>
                </vs-table>
            </div>
            <vs-pagination :total="totalItems" v-model="page" />
        </div>
    </div>
</template>

<script>
import Breadcrumb from "@/components/general/breadcrumb";
import StringRow from "@/components/table/rows/string-row";
import ActionsRow from "@/components/table/rows/actions-row";
import TableHeader from "@/components/table/table-header";
import EditData from "./edit-data";
import ShowData from "./show-data";
import ImageRow from "@/components/table/rows/image-row";

export default {
    name: "List",
    components: {
        ImageRow,
        Breadcrumb,
        StringRow,
        ActionsRow,
        TableHeader,
        EditData,
        ShowData,
    },
    data() {
        return {
            items: [],
            item: {},
            itemsPerPage: 20,
            isMounted: false,
            page: 1,
            totalItems: 0,
            query: "",
            sortKey: null,
            sortActive: null,
            edit: false,
            show: false,
            brands: [],
            models: [],
            brand_id: null,
            model_id: null,
        };
    },
    methods: {
        getData() {
            this.$vs.loading();
            const queryString = `per_page=${this.itemsPerPage}&page=${this.page}&query=${this.query}&field=${this.sortKey}&ordering=${this.sortActive}&brand_id=${this.brand_id}&model_id=${this.model_id}`;
            this.$http
                .get(`/user?${queryString}`)
                .then((res) => {
                    this.items = res.data.data.data;
                    this.edit = false;
                    this.add = false;
                    this.totalItems = Math.ceil(
                        res.data.data.total / this.itemsPerPage
                    );
                    this.$vs.loading.close();
                })
                .catch(() => {
                    this.$vs.notify({
                        title: "Something Error 🙁",
                        text: "Couldn't fetch data from the server",
                        color: "danger",
                    });
                    this.$vs.loading.close();
                });
        },
        exportData() {
            const queryString = `?r=a&brand_id=${this.brand_id}&model_id=${this.model_id}`;
            window.location.href =
                process.env.VUE_APP_ADMIN_URL +
                "/" +
                "export-users" +
                queryString;
        },
        onSuccessOperation() {
            this.getData();
        },
        showData(data) {
            this.show = true;
            this.item = data;
        },
        editData(data) {
            this.item = data;
            this.edit = true;
        },
        handleSearch(query) {
            this.query = query;
        },
        handleSort(key, active) {
            this.sortKey = key;
            this.sortActive = active === "asc" ? 1 : 0;
        },
        getBrands() {
            this.$vs.loading();
            this.$httpCars
                .get(`/brands?per_page=100000000`)
                .then((res) => {
                    this.$vs.loading.close();
                    this.brands = res.data.data.brands;
                })
                .catch(() => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Something Error",
                        text: "Couldn't fetch data from the server",
                        color: "danger",
                    });
                });
        },
        getModels() {
            this.$vs.loading();
            this.$httpCars
                .get(`/models?per_page=100000000`)
                .then((res) => {
                    this.$vs.loading.close();
                    this.models = res.data.data.models;
                })
                .catch(() => {
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Something Error",
                        text: "Couldn't fetch data from the server",
                        color: "danger",
                    });
                });
        },
    },
    computed: {
        currentPage() {
            if (this.isMounted) {
                return this.$refs.table.currentx;
            }
            return 0;
        },
        queriedItems() {
            return this.$refs.table
                ? this.$refs.table.queriedResults.length
                : this.items.length;
        },
    },
    watch: {
        page() {
            this.getData();
        },
        query() {
            this.getData();
        },
        sortKey() {
            this.getData();
        },
        sortActive() {
            this.getData();
        },
        itemsPerPage() {
            this.getData();
        },
    },
    mounted() {
        this.getData();
        this.getBrands();
        this.getModels();
    },
};
</script>

<style lang="scss">
#data-list-list-view {
    .vs-con-table {
        /*
        Below media-queries is fix for responsiveness of action buttons
        Note: If you change action buttons or layout of this page, Please remove below style
      */
        @media (max-width: 689px) {
            .vs-table--search {
                margin-left: 0;
                max-width: unset;
                width: 100%;

                .vs-table--search-input {
                    width: 100%;
                }
            }
        }

        @media (max-width: 461px) {
            .items-per-page-handler {
                display: none;
            }
        }

        @media (max-width: 341px) {
            .data-list-btn-container {
                width: 100%;

                .dd-actions,
                .btn-add-new {
                    width: 100%;
                    margin-right: 0 !important;
                }
            }
        }

        .product-name {
            max-width: 23rem;
        }

        .vs-table--header {
            display: flex;
            flex-wrap: wrap;
            margin-left: 1.5rem;
            margin-right: 1.5rem;

            > span {
                display: flex;
                flex-grow: 1;
            }

            .vs-table--search {
                padding-top: 0;

                .vs-table--search-input {
                    padding: 0.9rem 2.5rem;
                    font-size: 1rem;

                    & + i {
                        left: 1rem;
                    }

                    &:focus + i {
                        left: 1rem;
                    }
                }
            }
        }

        .vs-table {
            border-collapse: separate;
            border-spacing: 0 1.3rem;
            padding: 0 1rem;

            tr {
                box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);

                td {
                    padding: 20px;

                    &:first-child {
                        border-top-left-radius: 0.5rem;
                        border-bottom-left-radius: 0.5rem;
                    }

                    &:last-child {
                        border-top-right-radius: 0.5rem;
                        border-bottom-right-radius: 0.5rem;
                    }
                }

                td.td-check {
                    padding: 20px !important;
                }
            }
        }

        .vs-table--thead {
            th {
                padding-top: 0;
                padding-bottom: 0;

                .vs-table-text {
                    text-transform: uppercase;
                    font-weight: 600;
                }
            }

            th.td-check {
                padding: 0 15px !important;
            }

            tr {
                background: none;
                box-shadow: none;
            }
        }

        .vs-table--pagination {
            justify-content: center;
        }
    }
}
</style>
