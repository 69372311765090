<template>
    <div>
        <breadcrumb name="User" title="Show User" subtitle="Show" @onTitleClicked="backToList" />
        <vx-card title="User Information" class="mb-base">
          <vs-row vs-type="flex">
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="6"
              vs-sm="12"
            >
              <vs-row vs-type="flex">
                <vs-col
                  vs-type="flex"
                  vs-align="center"
                  vs-lg="12"
                >
                  <span class="font-semibold p-4">Avatar</span>
                  <img :src="item.photo" class="p-4" style="height:100px; width:100px; vertical-align: middle"
                       onerror="this.onerror=null;
                      this.src='https://uwosh.edu/facilities/wp-content/uploads/sites/105/2018/09/no-photo.png';"
                  />
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-align="center"
                  vs-lg="12"
                >
                  <span class="font-semibold p-4">Name</span>
                  <span class="p-4">{{item.name}}</span>
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-align="center"
                  vs-lg="12"
                >
                  <span class="font-semibold p-4">Email</span>
                  <span class="p-4">{{item.email}}</span>
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-align="center"
                  vs-lg="12"
                >
                  <span class="font-semibold p-4">National ID</span>
                  <span class="p-4">{{item.national_id}}</span>
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-align="center"
                  vs-lg="12"
                >
                  <span class="font-semibold p-4">Mobile</span>
                  <span class="p-4">{{item.mobile}}</span>
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-align="center"
                  vs-lg="12"
                >
                  <span class="font-semibold p-4">Joined at</span>
                  <span class="p-4">{{formatDate}}</span>
                </vs-col>
              </vs-row>
            </vs-col>
          </vs-row>
        </vx-card>

      <vx-card title="User Cars" class="mb-base">
        <vs-table :data="item.car" stripe>
          <template slot="thead">
            <vs-th>Image</vs-th>
            <vs-th>VIN Number</vs-th>
            <vs-th>Owner Name</vs-th>
            <vs-th>Owner Mobile</vs-th>
            <vs-th>City</vs-th>
            <vs-th>Year</vs-th>
            <vs-th>Chassis No.</vs-th>
            <vs-th>License</vs-th>
            <vs-th>Brand</vs-th>
            <vs-th>Model</vs-th>
            <vs-th>KMs</vs-th>
            <vs-th>Actions</vs-th>
          </template>

          <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
              <image-row :data="tr.image" />
              <string-row :data="tr.vin_number" />
              <string-row :data="tr.name" />
              <string-row :data="tr.mobile" />
              <string-row :data="tr.city" />
              <string-row :data="tr.year" />
              <string-row :data="tr.chassis_no" />
              <string-row :data="tr.license" />
              <string-row :data="tr.brand_en" />
              <string-row :data="tr.model_en" />
              <string-row :data="tr.kilometers" />
              <vs-td>
                <feather-icon
                  icon="RefreshCwIcon"
                  svgClasses="w-6 mt-1 h-6 mr-2 hover:text-success stroke-current"
                  @click="refreshCar(tr.vin_number, tr.mobile)"
                />
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vx-card>
    </div>
</template>

<script>
import { ChevronsRightIcon, HomeIcon } from "vue-feather-icons";
import Breadcrumb from "../../../components/general/breadcrumb";
import moment from 'moment'
import StringRow from "../../../components/table/rows/string-row";
import ImageRow from "../../../components/table/rows/image-row";

export default {
    name: "show-user",
    components: { ImageRow, StringRow, Breadcrumb, HomeIcon, ChevronsRightIcon },
    props: ['item'],
    computed: {
      formatDate() {
        return moment(this.item.created_at).format('YYYY - MM - DD')
      }
    },
    methods: {
        backToList() {
            this.$emit("cancel");
        },
        refreshCar(vin, mobile) {
          this.$vs.loading();
          this.$http
            .get(`/user/cars/update-form-sap?vin_number=${vin}&mobile=${mobile}`)
            .then(r => {
              this.item = r.data.data;
              this.$vs.loading.close();
            })
            .catch(e => {
              this.$vs.notify({
                title: "Something Error 🙁",
                text: "Couldn't fetch data from the server",
                color: "danger",
              });
              this.$vs.loading.close();
            })
        }
    },
  mounted() {
    console.log(this.item.car[0]);
  }
};
</script>
